.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.flex-col {
  flex-direction: column;
}

.center-x {
  display: flex;
  justify-content: center;
}

.center-y {
  display: flex;
  align-items: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-x {
  width: 100%;
}

.full-y {
  height: 100%;
}

.full {
  width: 100%;
  height: 100%;
}

.screen-x {
  width: 100vw;
}

.screen-y {
  height: 100vh;
}

.screen {
  width: 100vw;
  height: 100vh;
}

.min-screen-x {
  min-width: 100vw;
}

.min-screen-y {
  min-height: 100vh;
}

.relative {
  position: relative;
}

.min-screen {
  min-height: 100vh;
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* react suit */
.rs-modal-full {
  max-width: 1500px;
}
